<template>
  <div class="app">
    <v-card flat>
      <v-card-text class="d-flex">
        <div style="flex: 1;display: flex;flex-direction: column;justify-content: space-evenly;">
          <h3>如果您对我们的 App 有任何问题，您可以留言或发送问题到我们的电子邮件地址。</h3>
          <h3>邮箱地址：xi.chen@mtec.cc</h3>
          <h3>谢谢！</h3>
          <h3>iOS program design & system consultation.</h3>
          <h3>If you have any questions about our App, you can either leave a message or send the questions to our email address.</h3>
          <h3>We will answer them for you in the first time.</h3>
          <h3>Email: xi.chen@mtec.cc</h3>
          <h3>Thank you!</h3>
        </div>
      </v-card-text>
      <div style="padding: 20px"></div>
      <v-textarea
          outlined
          name="input-7-4"
          label="留言"
          value=""
          v-model="message"
      ></v-textarea>
      <div class="text-center">
        <v-btn class="ma-2" outlined color="indigo" @click="sendMessage">发送</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Support",
  data(){
    return {
      message: ""
    }
  },
  methods: {
    sendMessage(){
      if (this.message === ""){
        window.alert("留言不能为空！")
      } else {
        this.message = ""
        window.alert("留言发送成功！")
      }
    }
  },
}
</script>

<style scoped>
  .app{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
</style>